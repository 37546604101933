var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('VDataTable',{attrs:{"headers":[
        { text: 'ID', value: 'id', sortable: false },
        { text: 'ИНН', value: 'inn', sortable: false },
        { text: 'Организация', value: 'name', sortable: false },
        { text: 'Вид квалификации', value: 'qualification', sortable: false },
        { text: 'Статус', value: 'status.name', sortable: false } ],"footer-props":{
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 20, 50, 100, 500],
        itemsPerPageText: '',
      },"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.count,"mobile-breakpoint":0,"item-class":_vm.getClassByRow},on:{"update:options":function($event){_vm.options=$event},"click:row":function (ref) {
            var id = ref.id;

            return _vm.$router.push({ name: 'pkosmr/CounterpartyDetailView', params: { counterparty: id }});
},"update:page":function (page) { return _vm.$router.push({ query: Object.assign({}, _vm.$route.query, {page: page}) }); },"update:items-per-page":function (size) { return _vm.$router.push({ query: Object.assign({}, _vm.$route.query, {size: size}) }); }},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [(_vm.isReviewer || _vm.isSpectator || _vm.isGeneralReviewer)?_c('tr',[_vm._l((['id', 'inn', 'companyName', 'qualification']),function(key){return _c('td',{key:key},[_c('VTextField',{attrs:{"dense":"","clearable":"","hide-details":"","value":_vm.$route.query[key]},on:{"input":function (value) {
            var _obj;

            return _vm.$router.push({ query: Object.assign({}, _vm.$route.query, ( _obj = {}, _obj[key] = value, _obj )) });
      }}})],1)}),_c('td',[_c('VSelect',{attrs:{"dense":"","clearable":"","hide-details":"","items":_vm.statuses,"value":_vm.$route.query['status']},on:{"input":function (value) {
            var _obj;

            return _vm.$router.push({ query: Object.assign({}, _vm.$route.query, ( _obj = {}, _obj['status'] = value, _obj )) });
      }}})],1)],2):_vm._e()]},proxy:true},{key:"item.control",fn:function(ref){
      var ref_item = ref.item;
      var id = ref_item.id;
      var comments = ref_item.comments;
return [_vm._t("control",null,null,{ id: id, comments: comments })]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }